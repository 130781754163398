<template>
  <div>
    <a-row>
      <a-col :span="8">
        <a-form-model layout="inline" :model="form">
          <a-form-model-item label="" :label-col="{ span: 0, offset: 0 }" :wrapper-col="{ span: 24, offset: 0 }" w>
            <a-input v-model="form.search" placeholder="请输入委托单名称、编号、联系人、联系电话" style="width: 360px"/>
          </a-form-model-item>
          <a-form-model-item label="" :label-col="{ span: 0, offset: 0 }" :wrapper-col="{ span: 24, offset: 0 }">
            <a-button type="danger" @click="Clear()">清空</a-button>
          </a-form-model-item>
        </a-form-model>
      </a-col>
      <!--            <a-col :span="16" style="text-align: right">-->
      <!--                <a-button type="primary">导出数据</a-button>-->
      <!--            </a-col>-->
    </a-row>
    <a-row>
      <a-col :span="24">
        <a-space style="margin-top: 10px;" class="btner">

          <div class="btnerlist" @click="editclick()">
            <i class="iconfont icon-bianjimian" style="font-size: 18px"></i> 编辑
          </div>

          <div class="btnerlist">
            <i class="iconfont icon-guidang" style="font-size: 16px"></i> 归档
          </div>

          <div class="btnerlist" @click="copyclick()">
            <i class="iconfont icon-fuzhi" style="font-size: 16px"></i> 以此为模板新建委托单
          </div>
          <div class="btnerlist" @click="delClick()">
            <i class="iconfont icon-yixiaohui" style="font-size: 16px"></i> 删除检测
          </div>

        </a-space>


        <table class="layui-table" lay-size="sm" style="margin-top: -9px">
          <thead>
          <tr>
            <th>
              <a-checkbox v-model="all_check" @change="allChange"></a-checkbox>
            </th>
            <th @click="yinziclickup(1)" class="through" style="cursor: pointer; width: 300px">
              <span>所属分类</span>
              <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou"
                                   :class="[ordertype === 1 && sortrule === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou"
                                   :class="[ordertype === 1 && sortrule === 'desc' ?'classname':'']"
                                   style=" margin-top: -5px"/>
                        </span>
              </div>
            </th>
            <th @click="yinziclickup(2)" class="through" style="cursor: pointer; width: 300px">
              <span>编号</span>

              <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou"
                                   :class="[ordertype === 2 && sortrule === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou"
                                   :class="[ordertype === 2 && sortrule === 'desc' ?'classname':'']"
                                   style=" margin-top: -5px"/>
                        </span>
              </div>
            </th>
            <th>状态</th>
            <th>任务类型</th>
            <th>类型</th>
            <th>创建人</th>
            <th>委托单位</th>

            <th @click="yinziclickup(3)" class="through">

              <span class="">创建时间</span>
              <div style="display: inline-block; margin-top: -5px">
                            <span class="ant-table-column-sorter ">
                                    <a-icon type="caret-up" class="jiantou"
                                            :class="[ordertype === 3 && sortrule === 'asc' ?'classname':'']"/>
                                    <a-icon type="caret-down" class="jiantou"
                                            :class="[ordertype === 3 && sortrule === 'desc' ?'classname':'']"
                                            style=" margin-top: -5px"/>
                                    </span>
              </div>
            </th>
            <th>联系人</th>
            <th>联系电话</th>
            <th>是否加急</th>
            <th v-if="level === 2">递交上级</th>
            <!--                        <th>报告状态</th>-->
            <!--                        <th>操作</th>-->
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,key) in Task_list_page_list" :key="key" :class="[item.check ? 'active':'']"
              >
            <td @click="clickrow(key)">
              <a-checkbox v-model='item.check' @change="itemActiveChange"></a-checkbox>
            </td>
            <td @click="clickrow(key)">{{ item.name }}</td>
            <td @click="clickrow(key)">{{ item.task_num }}</td>
            <td @click="clickrow(key)">{{ item.task_step }}</td>
            <td @click="clickrow(key)">{{ item.task_type === 1 ? '内部' : '外部' }}</td>
            <td @click="clickrow(key)">{{ item.task_type_trun }}</td>
            <td @click="clickrow(key)">{{ item.admin_name }}</td>
            <td @click="clickrow(key)">{{ item.com_name }}</td>
            <td @click="clickrow(key)">{{ item.create_time }}</td>
            <td @click="clickrow(key)">{{ item.com_per }}</td>
            <td @click="clickrow(key)">{{ item.com_phone }}</td>
            <td @click="clickrow(key)">{{ item.is_fast_trun }}</td>
            <td v-if="level === 2">
              <div v-if="item.bind_uuid === ''">否</div>
              <div v-if="item.bind_uuid !== ''">
                <div v-if="item.callback === ''">待检测</div>
                <div v-if="item.callback !== ''">
                  <a target="_blank" :href="item.callback">查看报告</a>
                </div>
              </div>
            </td>
            <!--                        <td>未编制</td>-->
            <!--                        <td style="text-align: left">-->

            <!--                            <a-button type="link" @click="jumpToJiance(item)">开始报告编制</a-button>-->
            <!--                            <a-button type="link">编辑</a-button>-->
            <!--                            <a-button type="link">归档</a-button>-->
            <!--                            <a-button type="link" class="yellow">以此为模板新建委托单</a-button>-->
            <!--                            <a-button type="link" @click="delClick(item)" v-if="item.report_id != 0 " class="redcolor">-->
            <!--                                删除检测-->
            <!--                            </a-button>-->

            <!--                        </td>-->
          </tr>

          </tbody>
        </table>
        <a-pagination :current="page" :pageSize="pagesize" :total="count" v-if="count>pagesize" @change="onChange"/>
      </a-col>
    </a-row>
    <!--开始检测-->
    <div v-if="weituodan_info">
      <weituodan :weituodan_info="weituodan_info" :weituodan_check_id="weituodan_check_id"
                 :weituodan_check_ids="weituodan_check_ids" :weituodan_time="weituodan_time"
                 :close="onCloseDrawerweituodan" :visible="drawer_weituodan"></weituodan>
    </div>
  </div>
</template>

<script>
import weituodan from "./weituodan/weituodan.vue";

export default {
  name: "jianceyinziguanli",
  components: {weituodan},
  data() {
    return {
      level:app_config.level,
      page_show: false,
      page: 1,
      count: 0,
      pagesize: 0,
      Task_list_page_list: [],
      form: {
        search: '',
        type: 0,
      },
      // 定义控制抽屉显示的变量
      weituodan_check_ids: false,
      weituodan_check_id: false,
      weituodan_time: 0,
      weituodan_info: false,
      drawer_weituodan: false,
      ordertype: 0,
      sortrule: 'asc',
      all_check: false,
    }
  },
  watch: {
    form: {
      handler() {
        if (this.page_show) this.searchClick()
      },
      deep: true
    },

    $route: {
      handler() {
        this.mountedDo()
      },
      deep: true
    },
    itemActiveChange() {
      let do_ = true
      this.Task_list_page_list.map((item, key) => {
        if (!item.check) do_ = false
      })
      this.all_check = do_
    },
    allChange(e) {
      this.Task_list_page_list.map((item, key) => {
        this.$set(this.Task_list_page_list[key], 'check', this.all_check)
      })
    },
  },
  mounted() {
    this.mountedDo()
  },
  methods: {


    clickrow(key) {
      this.$set(this.Task_list_page_list[key], 'check', !this.Task_list_page_list[key].check)
      this.itemActiveChange()
    },
    itemActiveChange() {
      let do_ = true
      this.Task_list_page_list.map((item, key) => {
        if (!item.check) do_ = false
      })
      this.all_check = do_
    },
    allChange(e) {
      this.Task_list_page_list.map((item, key) => {
        this.$set(this.Task_list_page_list[key], 'check', this.all_check)
      })
    },

    mountedDo() {
      this.page = Number(this.$route.query.page) || 1;
      this.form.search = this.$route.query.search || '';
      this.form.type = this.$route.query.type || '0';
      this.Task_list_page()
      setTimeout(() => {
        this.page_show = true
      }, 100)
    },

    //搜索
    searchClick() {
      //console.log(this.form.keywords)
      this.$router.push({
        query: {
          id: this.id,
          search: this.form.search,
          page: 1,
        }
      })
    },

    Clear() {
      this.form.search = '';
      this.page = 1;
    },

    // 分页
    onChange(page) {
      this.$router.push({
        query: {
          page: page,
          ordertype: this.ordertype,
          sortrule: this.sortrule,

        }
      })
    },
    yinziclickup(ordertype) {
      if (this.ordertype !== ordertype) {
        this.ordertype = ordertype
        this.sortrule = 'asc'
      } else {
        if (this.sortrule === 'asc') {
          this.sortrule = 'desc'
        } else {
          this.sortrule = 'asc'
        }
      }
      this.$sa0.post({
        url: this.$api('Task_list_page'),
        data: {
          page: this.page,
          search: this.form.search,
          ordertype: this.ordertype,
          sortrule: this.sortrule,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.Task_list_page_list = response.data.list;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },

    Task_list_page() {

      this.$sa0.post({
        url: this.$api('Task_list_page'),
        data: {
          page: this.page,
          search: this.form.search,
          type: this.form.type,
          ordertype: this.ordertype,
          sortrule: this.sortrule,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.Task_list_page_list = response.data.list;
            console.log(this.Task_list_page_list);
            this.count = Number(response.data.count);//分页
            this.pagesize = Number(response.data.size);//分页
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },

    // w删除
    delClick() {
      let id = 0
      let count = 0
      this.Task_list_page_list.map((item, key) => {
        if (item.check) {
          this.weituodan_info = item;
          id = item.report_id
          count++
        }
      })
      if (!id) return layer.msg('请选择委托单')
      if (count !== 1) return layer.msg('只能选择一个委托单')
      this.$sa0.post({
        url: this.$api('Delete_report'),
        data: {
          id: id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg(response.message)
            this.Task_list_page()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },

    editclick() {
      let id = 0
      let count = 0
      this.Task_list_page_list.map((item, key) => {
        if (item.check) {
          id = item.id
          count++
        }
      })
      if (!id) return layer.msg('请选择委托单')
      if (count !== 1) return layer.msg('只能选择一个委托单')
      console.log(999, id)
      this.$router.push('/addweituodan/addweituodan/' + id + '?page=1&type=0&search=&edit=1&time=1649384269181')
    },

    copyclick() {
      let id = 0
      let count = 0
      this.Task_list_page_list.map((item, key) => {
        if (item.check) {
          id = item.id
          count++
        }
      })
      if (!id) return layer.msg('请选择委托单')
      if (count !== 1) return layer.msg('只能选择一个委托单')
      console.log(999, id)
      this.$router.push('/addweituodan/addweituodan/0?page=1&type=0&search=&edit=1&time=1649384812460')
    },


    onCloseDrawerweituodan() {
      this.Task_list_page();
      this.drawer_weituodan = false;
      this.weituodan_info = false;
    },


  }
}
</script>

<style>
.through:hover {
  cursor: pointer;
}

.ant-table-column-sorter {
  display: inline-flex;
  flex-direction: column;
  margin-top: 0px;
  margin-left: 5px;
  cursor: pointer;
  width: 10px;
  vertical-align: middle;
}

.jiantou {
  color: #999999
}

.classname {
  color: #1890ff;
}

.fl {
  float: left
}

table tr {
  cursor: pointer
}


.btner {
  width: 100%;
  background: #f9f9f9;
  padding: 5px 10px;
  font-size: 12px;
  color: #333;
  border: 1px solid #eeeeee;
  margin-bottom: 10px;
}

.active {
  background: #ebf6ff;
}

.btnerlist {
  cursor: pointer;
  border-right: 1px solid #eeeeee;
  height: 26px;
  line-height: 26px;
  padding: 0 10px
}

.btnerlist :last-child(1) {
  border: none
}

.fcadd {
  color: #66c14c !important;
  font-size: 18px !important;
}

.btnerlist i {
  color: #999999;
  font-size: 14px;
  vertical-align: bottom;
}

.btnerlist:hover {
  background: #1592fe;
  color: #ffffff;
  border-radius: 4px;

}

.btnerlist:hover i, .btnerlist:hover.fcadd {
  color: #ffffff !important
}

.isthis {
  background: #1592fe;
  color: #ffffff;
  border-radius: 4px;
}

.isthis i {
  color: #ffffff
}
</style>
