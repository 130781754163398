
<template>
  <div>
    <!--:visible="visible"  赋值 显示变量-->
    <!--@close="onClose"    赋值 关闭函数-->
    <a-drawer title="选择检测报告模板" width="36%" placement="right" :closable="true" :visible="visible" @close="onClose">
      <div>
        <table class="layui-table">
          <thead>
          <tr>
            <th>检测报告模板名称</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,key) in Report_tpl_list_list" >
            <td>{{item.name}}{{item.is_del}}</td>
            <td>
              <a-space>
                <a-button type="primary" @click="doClick(item)">选择</a-button>
              </a-space>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </a-drawer>

  </div>
</template>
<script>
  /***
   Name: example3
   Code: sa0ChunLuyu
   Time: 2021/10/16 09:47
   Remark: 编辑抽屉示例的抽屉组件
   */
  export default {
    // 接收 显示变量 和 关闭函数
    props: ['visible', 'close','weituodan_check_id','weituodan_check_ids','weituodan_info'],
    data() {
      return {
        Report_tpl_list_list:'',
        report_tpl_id: '',
        tpl_id: '',
      }
    },
    mounted() {
      this.Report_tpl_list()

    },
    methods: {

      //选择
      doClick(item) {
        //console.log(this.weituodan_info.id,item.id);
        let data = {
          report_tpl_id: item.id,
          task_id: this.weituodan_info.id,
        }
        //console.log(data)
        //console.log(JSON.stringify(data))
        this.$sa0.post({
          url: this.$api('Create_report'),
          data: data,
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (res) => {
              layer.msg('已选择')
              this.$router.push('/startCheck/' + res.data.id)
              this.close()
            },
            error: (res) => {
              layer.msg(res.message)
            }
          })
        })
      },
      // w获取模板列表
      Report_tpl_list() {
        this.$sa0.post({
          url: this.$api('Report_tpl_list'),
          data: {},
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              this.Report_tpl_list_list = response.data.list;
              //console.log(JSON.stringify(this.Report_tpl_list_list))
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })
      },


      onClose() {
        this.close();
      },
    }
  }
</script>
<style scoped>
  .layui-table th{text-align: left}
</style>
